import React from "react";
import "./Cartell.scss";
import html2canvas from "html2canvas";

export default class Cartell extends React.Component<{}, {
  cartell: {
    titol: string;
    background: string;
    esdeveniments: {
      titol: string;
      data: string;
      hora: string;
      lloc: string;
      colles: string[];
    }[];
  } | null;
}> {
  constructor(props: any) {
    super(props);
    this.state = {
      cartell: null
    };
  }

  render(): React.ReactNode {
    return (
      <section className="cartell">
        <div className="options">
          <select name="" id="">
            <option value="0">Cartell (3:4)</option>
            <option value="1">XXSS (1:1)</option>
            <option value="2">Postal</option>
          </select>
          <button className="download" onClick={
            () => {
              const element = document.querySelector('div.preview') as HTMLElement;
              if (!element) return;
              html2canvas(element, {
                scale: 1,
              }).then((canvas) => {
                const link = document.createElement('a');
                link.download = 'cartell.png';
                link.href = canvas.toDataURL('image/png');
                link.click();
              });
            }
          }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
              <path d="M3.00012 14L3.23397 14.6626C4.14412 17.2413 4.5992 18.5307 5.63754 19.2654C6.67588 20 8.04322 20 10.7779 20H13.2224C15.957 20 17.3244 20 18.3627 19.2654C19.401 18.5307 19.8561 17.2413 20.7663 14.6626L21.0001 14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M12.0001 14V4M12.0001 14C11.2999 14 9.99165 12.0057 9.50012 11.5M12.0001 14C12.7003 14 14.0086 12.0057 14.5001 11.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
        <div className="sheet" onClick={
          (e) => {
            if ((e.target as HTMLElement).classList.contains('sheet')) {
              const input = document.getElementsByName('file')?.[0] as HTMLInputElement;
              input.click();
            }
          }
        }>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
            <path d="M7.07523 3.88403C10.2874 4.8348 13.7126 4.8348 16.9248 3.88403C19.517 3.11677 20.813 2.73313 21.4065 3.20921C22 3.68529 22 4.90772 22 7.35256V16.6474C22 19.0923 22 20.3147 21.4065 20.7908C20.813 21.2669 19.5169 20.8832 16.9248 20.116C13.7126 19.1652 10.2874 19.1652 7.07523 20.116C4.48305 20.8832 3.18696 21.2669 2.59348 20.7908C2 20.3147 2 19.0923 2 16.6474V7.35256C2 4.90772 2 3.68529 2.59348 3.20921C3.18696 2.73313 4.48305 3.11677 7.07523 3.88403Z" stroke="currentColor" strokeWidth="1" />
            <path d="M7 20C10.9469 15.8426 15.3824 10.3291 22 14.4643" stroke="currentColor" strokeWidth="1" />
            <path d="M9 8.5C9 9.32843 8.32843 10 7.5 10C6.67157 10 6 9.32843 6 8.5C6 7.67157 6.67157 7 7.5 7C8.32843 7 9 7.67157 9 8.5Z" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <input type="file" name="file" id="" style={{ display: 'none' }} onInput={
            (e) => {
              const file = (e.target as HTMLInputElement).files?.[0];
              const hasImage = document.querySelector('div.sheet img');
              if (hasImage) hasImage.remove();
              if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                  const image = document.createElement('img');
                  image.src = reader.result as string;
                  image.className = 'background';
                  document.querySelector('div.sheet')?.appendChild(image);
                  this.setState({
                    cartell: {
                      ...this.state.cartell,
                      background: reader.result as string
                    }
                  });
                }
                reader.readAsDataURL(file);
              }
            }
          } />
          <button className="deleteBackground" onClick={
            () => {
              const image = document.querySelector('div.sheet img');
              if (image) {
                image.remove();
                this.setState({
                  cartell: {
                    ...this.state.cartell,
                    background: ''
                  }
                });
              }
            }
          }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
              <path d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M9.5 16.5L9.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M14.5 16.5L14.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
          </button>
          <div className="title">
            <h2>Castellers de Mollet</h2>
            <h2 className="editable" contentEditable onInput={
              (e) => {
                this.setState({
                  cartell: {
                    ...this.state.cartell,
                    titol: (e.target as HTMLElement).innerText
                  }
                });
              }
            }></h2>
          </div>
          {
            new Array(1).fill(0).map((e, i) => {
              const esdeveniment = this.state.cartell?.esdeveniments?.[i];
              return (
                <article className="event" key={i}>
                  <input type="text" name="titol" placeholder="Títol del Esdeveniment" value={e.titol} />
                  <div className="data">
                    <input type="text" name="data" placeholder="Data" value={e.data} onFocus={
                      (e) => {
                        (e.target as HTMLInputElement).type = 'date';
                      }
                    } onBlur={
                      (e) => {
                        (e.target as HTMLInputElement).type = 'text';
                      }
                    } />
                    <input type="text" name="hora" placeholder="Hora" value={e.hora} onFocus={
                      (e) => {
                        (e.target as HTMLInputElement).type = 'time';
                      }
                    } onBlur={
                      (e) => {
                        (e.target as HTMLInputElement).type = 'text';
                      }
                    } />
                  </div>
                  <input type="text" name="lloc" placeholder="Lloc / Plaça" value={e.lloc} />
                  <div className="colles">
                    {
                      new Array(e.colles ? e.colles.length : 1).fill(undefined).map((colla, j) => {
                        return (
                          <span key={j} contentEditable className="colla" spellCheck='false' onInput={
                            (e) => {
                              const colles = document.querySelector('div.colles')?.getElementsByClassName('colla');
                              const newColles = Array.from(colles).map((colla) => (colla as HTMLElement).innerText);
                              newColles[j] = (e.target as HTMLElement).innerText;
                              this.setState({
                                cartell: {
                                  ...this.state.cartell,
                                  esdeveniments: this.state.cartell?.esdeveniments?.map((esdeveniment, k) => {
                                    if (k === i) {
                                      return {
                                        ...esdeveniment,
                                        colles: newColles
                                      };
                                    }
                                    return esdeveniment;
                                  })
                                }
                              });
                            }
                          }>{colla}</span>
                        );
                      })
                    }
                    <button onClick={
                      (e) => {
                        const colla = document.createElement('span');
                        colla.contentEditable = 'true';
                        colla.className = 'colla';
                        colla.spellcheck = false;
                        const colles = document.querySelector('div.colles')?.getElementsByClassName('colla');
                        colles?.[colles.length - 1].after(colla);
                        colla.focus();
                      }
                    }>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                        <path d="M12 4V20M20 12H4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </button>
                  </div>
                </article>
              );
            })
          }
          <div className="logos">

          </div>
        </div>
        <div className="preview">
          <svg>
            <mask id="mask">
              <rect width="100%" height="100%" fill="white" />
              <circle cx="100%" cy="85%" r="3.5%" fill="black" />
              <circle cx="96.5%" cy="92.5%" r="3.5%" fill="black" />
              <circle cx="93%" cy="100%" r="3.5%" fill="black" />
              <rect width="35%" height="10%" x="32.5%" fill="black" />
            </mask>
            <rect width="100%" height="100%" fill="transparent" stroke="#fff" strokeWidth={3} mask="url(#mask)" />
            <circle cx="100%" cy="85%" r="1.5%" fill="var(--green)" />
            <circle cx="96.5%" cy="92.5%" r="1.5%" fill="#111" />
            <circle cx="93%" cy="100%" r="1.5%" fill="#fff" />
            <text x="50%" y="2.5%" fill="#fff" width="40%" fontSize={15} fontWeight={800} style={{textAlign: 'center'}} textAnchor="middle">CASTELLERS DE MOLLET</text>
          </svg>
          <div className="title">
            {
              this.state.cartell?.titol ? <h2 className="titol">{this.state.cartell.titol}</h2> : <h2 className="titol">Escriu un titol</h2>
            }
          </div>
          {
            this.state.cartell?.background ? <img src={this.state.cartell.background} alt="Cartell" className="background" /> : <div className="empty">Selecciona una imatge de fons</div>
          }
        </div>
      </section>
    );
  }
}